const nodeApi = {
  'bj.cardhub.org.cn':{
    api: 'https://apicard.cardhub.org.cn/',
    secret: 'cardhub:$2a$10$Cs7LbIomiLNqjsUt6ZfLw.crTtUG0OCyxc2ZoHPS3JKPqPMEZcibG',
    channel: '卡牌'
  },
  'cc1.ccpscw.com': {
    api: 'https://cc3.ccpscw.com/',
    secret: 'chaowan:chaowan-secret',
    channel: '潮玩'
  },
  'chain.zhzlkj.net': {
    api: 'https://pochain.zhzlkj.net/',
    secret: 'TradingCenter:$2a$10$HMh7slu/wOvic/CnuW3U6OqOti3/DIUoBnfyr.2T4weZjr9Jq8Kq.',
    channel: '交易中心'
  },
  'os.cnfxcloud.com:51443': {
    api: 'https://api.cnfxcloud.com:51443/',
    secret: '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy',
    channel: '文化大数据测试'
  },
  'os.fxiyun.com:51443': {
    api: 'https://api.fxiyun.com:51443/',
    secret: '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy',
    channel: '文化大数据测试'
  },
  'mgta1.fxiyun.com:51443': {
    api: 'https://api1a1.fxiyun.com:51443/',
    secret: '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy',
    channel: '文化大数据正式'
  },
  'www.dxc.pro': {
    api: 'https://api.dxc.pro/',
    secret: '1712655665983439200:$2a$10$UqBjP5c4IxSxVOvqoJZxZ.VASuRMczEVqERhYVyXGpOO604JFu/YK',
    channel: '电子商会'
  },
  'www.sdocenter.cn': {
    api: 'https://api.sdocenter.cn/',
    secret: '1717738659035243507:$2a$10$zgL7oI2PidK9iFTr1KX.EeDnrJDwxQEZdvDxMoOFpx4QSsWwSK/K2',
    channel: '舞台剧'
  },
  'backup.sdocenter.cn': {
    api: 'https://api.sdocenter.cn/',
    secret: '1717738659035243507:$2a$10$zgL7oI2PidK9iFTr1KX.EeDnrJDwxQEZdvDxMoOFpx4QSsWwSK/K2',
    channel: '舞台剧'
  },
  'test-www.sdocenter.cn': {
    api: 'http://test-api.sdocenter.cn/',
    secret: '1717738659035243507:$2a$10$zgL7oI2PidK9iFTr1KX.EeDnrJDwxQEZdvDxMoOFpx4QSsWwSK/K2',
    channel: '舞台剧'
  },
  'supernode.licenseinfo.cn': {
    api: 'https://supernode.wespace.cn/',
    secret: 'wespace:wespace-secret',
    channel: '时空社_官网'
  },
  'bql1.xiutang.xyz': {
    api: 'https://bql3.xiutang.xyz',
    secret: '1718900590660091162:$2a$10$1A7HDlqmDl99/RDUPF.5TurZkGJ7mgIaSfH99fJATfAwKAZYdeezq',
    channel: '数字商品重庆节点'
  },
  'www.shanganwenhua.com': {
    api: 'https://api.shanganwenhua.com',
    secret: '1726476907941246807:$2a$10$mLMBuNrnXYG8bug2wooqzuF5AtcBG7Ps4.DiqP38O3OwDU91tcmzG',
    channel: '舞台剧-深圳节点'
  },
}
export default nodeApi