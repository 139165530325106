//处理价格 保留小数点2位
function handlePrice(val) {
  let temp = val.toString();
  temp = temp.replace(/。/g, ".");
  temp = temp.replace(/[^\d.]/g, "")//情书数字和.以外的字符
  temp = temp.replace(/^\./g, "");//验证第一个字符是数字
  temp = temp.replace(/\.{2,}/g, "");//只保留第一个，清楚多余得
  temp = temp.replace('.', "$#$").replace(/\./g, "").replace("$#$", '.');
  temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两位小数
  return temp
}
// 校验输入框不能有特殊字符
function checkSpecialKey(str) {
  let specialKey = "[`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
  for (let i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) != -1) {
      return false;
    }
  }
  return true;
}
export default {
  handlePrice,
  checkSpecialKey,
}