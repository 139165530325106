import axios from 'axios'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import router from '../router/index'
import nodeApi from './nodeApi'

let api = ''
let secret = ''
let host = window.location.host
if (host.includes('localhost')) {
    host = 'www.sdocenter.cn'
}
api = nodeApi[host].api
secret = nodeApi[host].secret
// if (host == 'www.cardhub.org.cn') {
//   api = 'https://apicard.cardhub.org.cn/' // 卡牌
//   secret = 'cardhub:$2a$10$Cs7LbIomiLNqjsUt6ZfLw.crTtUG0OCyxc2ZoHPS3JKPqPMEZcibG'
// } else if (host == 'cc1.ccpscw.com') {
//   api = 'https://cc3.ccpscw.com/' // 潮玩
//   secret = 'chaowan:chaowan-secret'
// } else if (host == 'chain.zhzlkj.net') {
//   api = 'https://pochain.zhzlkj.net/' // 交易中心
//   secret = 'TradingCenter:$2a$10$HMh7slu/wOvic/CnuW3U6OqOti3/DIUoBnfyr.2T4weZjr9Jq8Kq.'
// } else if (host == 'os.cnfxcloud.com:51443') {
//   api = 'https://api.cnfxcloud.com:51443/' // 文化大数据
//   secret = '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy'
// } else if (host == 'www.dxc.pro') {
//   api = 'https://api.dxc.pro/' // 电子商会
//   secret = '1712655665983439200:$2a$10$UqBjP5c4IxSxVOvqoJZxZ.VASuRMczEVqERhYVyXGpOO604JFu/YK'
// } else {
//   api = 'https://supernode.wespace.cn/' // supernode
//   secret = 'supernode:supernode-secret'
// //   api = 'https://api.dxc.pro/' // 卡牌
// //   secret = '1712655665983439200:$2a$10$UqBjP5c4IxSxVOvqoJZxZ.VASuRMczEVqERhYVyXGpOO604JFu/YK'
// }
// 创建axios的对象
const instance = axios.create({
    // baseURL: "http://117.107.213.242:10089/",  //测试域名
    // baseURL: "https://node.wespace.cn/",  //正式域名
    // baseURL: "https://supernode.wespace.cn/",  //正式域名
    // baseURL: "https://apicard.cardhub.org.cn/",  //卡牌域名
    // baseURL: "https://pochain.zhzlkj.net/",  //交易中心域名
    // baseURL: "https://cc3.ccpscw.com/",  //潮玩域名
    // baseURL: "https://api.cnfxcloud.com:51443/",  //文化大数据
    // baseURL: "https://api.dxc.pro/",  //电子商会
    baseURL: api,
    // timeout: 600000
})
const IGNORED_INTERFACES = ['/userApi/user/login', '/userApi/user/v2/update/password', '/userApi/user/login/register', '/userApi/user/supernode/automatic/login'];
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    config => {
        const ignored = IGNORED_INTERFACES.some(url => config.url.startsWith(url));
        if(ignored === true){
            // var str = 'supernode:supernode-secret';
            // var str = 'cardhub:$2a$10$Cs7LbIomiLNqjsUt6ZfLw.crTtUG0OCyxc2ZoHPS3JKPqPMEZcibG';//卡牌
            // var str = 'chaowan:chaowan-secret'; //潮玩
            // var str = 'TradingCenter:$2a$10$HMh7slu/wOvic/CnuW3U6OqOti3/DIUoBnfyr.2T4weZjr9Jq8Kq.';//交易中心
            // var str = '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy';//文化大数据
            // var str = '1712655665983439200:$2a$10$UqBjP5c4IxSxVOvqoJZxZ.VASuRMczEVqERhYVyXGpOO604JFu/YK';//电子商会
            var str = secret
            var encoder = new TextEncoder();
            var data = encoder.encode(str);
            var encoded = btoa(String.fromCharCode.apply(null, data));
            console.log("11111", encoded);
            config.headers['Authorization'] = ('Basic ' + encoded)
        }else if(ignored === false){
            config.headers['Authorization'] = ('Bearer ' + sessionStorage.getItem('usn-token'))
        }
        // if (sessionStorage.getItem('usn-token')) {
        //     config.headers['Authorization'] = ('Bearer ' + sessionStorage.getItem('usn-token'))
        // }
        // else {
        //     // var str = 'supernode:supernode-secret';
        //     // // var str = 'cardhub:$2a$10$Cs7LbIomiLNqjsUt6ZfLw.crTtUG0OCyxc2ZoHPS3JKPqPMEZcibG';//卡牌
        //     // // var str = 'chaowan:chaowan-secret'; //潮玩
        //     // // var str = 'TradingCenter:$2a$10$HMh7slu/wOvic/CnuW3U6OqOti3/DIUoBnfyr.2T4weZjr9Jq8Kq.';//交易中心
        //     // // var str = '1706219928262078676:$2a$10$AztVxKDAJhDBDfwP4rUb.u.zuaXqlqaLEfqSpnkDXUsUTm3W3NwGy';//文化大数据
        //     // // var str = '1712655665983439200:$2a$10$UqBjP5c4IxSxVOvqoJZxZ.VASuRMczEVqERhYVyXGpOO604JFu/YK';//电子商会
        //     // var encoder = new TextEncoder();
        //     // var data = encoder.encode(str);
        //     // var encoded = btoa(String.fromCharCode.apply(null, data));
        //     // console.log("11111", encoded);
        //     // config.headers['Authorization'] = ('Basic ' + encoded)
        // }
        return config
    },
    error => {
        // do something with request error （在响应出现error时做些什么）
        console.log("111",error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    response => {
        const res = response.data
        // console.log("res",res)
        switch (res.code) {
            case 400:
                Message({
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 401:
                router.push({ name: 'login' })
                // window.location.reload()
                Message({
                    message: '请重新登录',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 405:
                Message({
                    message: 'http请求方式有误',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 500:
                Message({
                    message: '服务器出了点小差，请稍后再试',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 501:
                Message({
                    message: '服务器不支持当前请求所需要的某个功能',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            }
        return res
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

// 封装get和post请求
export function get (url, params) {
    return instance.get(url, { params })
}

export function post (url, data) {
    return instance.post(url, data)
}

export default instance;