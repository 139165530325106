function throttle(methods, params) {
	// methods是需要点击后需要执行的函数， info是点击需要传的参数
    // params是执行函数需要传的参数
    let that = this;
    if (that.noClick && methods) {
        that.noClick = false;
        if (params) {
            methods(params);
        } else {
            methods()
        }
        setTimeout(function () {
            that.noClick = true;
        }, 3000)    //三秒内不能再次点击
    } else {
        console.log("重复点击，请稍后再试")
    }
}
 
//noClick 是点击界面的data中添加的初始化是否可点击的状态
 
export default { throttle };