<template>
  <div>
    <div class="caaeg" v-if="flag">
      <div class="topNav">
        <div class="navTag">
          <!-- supernode -->
          <p class="title" @click="toTop" v-if="linkItem == 0">版权登记平台 </p>
          <!-- 交易中心 -->
          <p class="titlel" @click="toTop" v-if="linkItem == 3"></p>
          <!-- 卡牌 -->
          <p class="logotip" @click="toTop" v-if="linkItem == 1"></p>
          <!-- 舞台剧 -->
          <p class="logoStage" @click="toTop" v-if="linkItem == 4"></p>
          <!-- 电子商会 -->
          <p class="logotipz" @click="toTop" v-if="linkItem == 2"></p>
          <!-- 文化大数据 -->
          <p class="logoultural" @click="toTop" v-if="linkItem == 5"></p>
          <div class="nvaList" v-for="(item,index) in navList" :key="index">
            <div v-if="!item.children.length" class="headTitleBox" :class="activeCode === item.path ? 'active': ''">
              <p class="nav"  :class="activeCode === item.path ? 'active': ''" @click="toChildren(item)">{{item.label}}</p>
              <i :class="activeCode === item.path ? 'caaegati': ''"></i>
            </div>
            <p v-else>
              <el-dropdown @command="handleNav" placement="top-start">
                <span class="el-dropdown-link">
                  {{item.label}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="pl in item.children" :key="pl.code" :command="pl">{{pl.label}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </p>
          </div>
        </div>
        <div class="navBtn">
          <el-button v-if="!usnInfo" type="primary" @click="tologin">登录/注册</el-button>
          <el-dropdown v-else trigger="click" @command="handleCommand">
            <span class="el-dropdown-link" style="whiteSpace: nowrap">
              <img style="borderRadius: 50%" :src="usnurl?(usnurl.substring(0, 4)==='http'?usnurl:fileUrl+usnurl):require('../assets/account/headimg.png')" alt="">
              {{usnName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item command="account">我的账号</el-dropdown-item>
              <!-- <el-dropdown-item command="oderGoods">我的订单</el-dropdown-item> -->
              <el-dropdown-item command="exitLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <router-link to="/home"></router-link>
    <router-link to="/"></router-link>
    <router-link to="/stagePlay"></router-link>
    <router-link to="/registerList"></router-link>
    <router-link to="/transferenceList"></router-link>
    <router-link to="/login"></router-link>
    <router-link to="/logon"></router-link>
    <router-link to="/account"></router-link>
    <router-link to="/oderGoods"></router-link>
    <router-link to="/assetIssuance"></router-link>
    <router-link to="/nodeAccess"></router-link>
    <router-view></router-view>
    <my-footer :class='fixFlag?"fixed":""'  v-if="$route.meta.shovalues"/>
  </div>
  <!-- </div> -->
</template>

<script>
import { getMenusList } from '@/api/stagePlay.js'
import MyFooter from '../views/introduce/footer.vue'
  export default {
    components: {
      MyFooter
    },
    data(){
      return{
        fixFlag: false,
        flag: true,
        navList:[
        {
          code: '/',
          label: '首页',
          path: '/',
          children: []
        },{
          code: '7',
          label: '版权存证',
          path: '/exitEvidence',
          children: []
        },{
          code: '4',
          label: '版权注册',
          path: '/copyright/home',
          children: []
        },
        {
          code: '6',
          label: '版权转让',
          path: '/transferenceList',
          children: []
        },
        {
          code: '3',
          label: '版权授权',
          path: '/registerList',
          children: []
        },
        {
          code: '8',
          label: '版权发行',
          path: '/assetIssuance',
          children: []
        },
        {
            code: '9',
            label: '资产管理',
            path: '/assetManagement',
            children: []
          },
          {
          code: '5',
          label: '数字版权查询',
          path: '',
          children: [
            {
              code: 'licenseinfo',
              label: '版权链查证',
              url: 'https://www.licenseinfo.cn/'
            },
            {
              code: 'bjxwcbj',
              label: '北京市版权局区块链查证平台',
              url: 'https://szzs.bjxwcbj.gov.cn/'
            },
            {
              code: 'bjinternetcourt',
              label: '北京互联网法院天平链查证',
              url: 'https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch'
            },
            // {
            //   code: '10',
            //   label: '节点接入申请',
            //   path: '/nodeAccess'
            // },
          ]
        }],
        activeCode: '/',
        usnInfo: '',
        usnName: '',
        usnurl: '',
        fileUrl: '',
        linkItem: 0
      }
    },
    created(){
      let routers = this.$router.options.routes
      let hash = this.$route.path
      this.flag = routers[0].children.some(val => val.path  == ('/' + hash.split('/')[1]))
      if (this.$route.meta.shovalues) {
        this.resizeFun()
        window.onresize=()=>{
          this.resizeFun()
        }
      }
      this.fileUrl = this.$store.state.file_url
      if (this.$route.query.usn) {
        sessionStorage.setItem('usn', this.$route.query.usn)
      }
      if (sessionStorage.getItem('activePath')) {
        this.activeCode = sessionStorage.getItem('activePath')
      }
      this.usnInfo = sessionStorage.getItem('usnInfo')?JSON.parse(sessionStorage.getItem('usnInfo')) : ''
      let host = window.location.host
      if (host == 'www.cardhub.org.cn') {
        this.linkItem =  1 // 卡牌
      } else if (host == 'cc1.ccpscw.com') {
        this.linkItem =  0// 潮玩
      } else if (host == 'chain.zhzlkj.net') {
        this.linkItem =  3 // 交易中心
      } else if (host == 'os.fxiyun.com:51443') {
        // this.linkItem =  0 // 文化大数据
        this.linkItem =  5 // 文化大数据
      } else if (host == 'mgta1.fxiyun.com:51443') {
        // this.linkItem =  0 // 文化大数据
        this.linkItem =  5 // 文化大数据
      }else if (host == 'www.dxc.pro') {
        this.linkItem =  2 // 电子商会
      } else if (host == 'www.sdocenter.cn') {
        this.linkItem =  4 // 舞台剧
        this.getMenus()
      }else if (host == 'backup.sdocenter.cn') {
        this.linkItem =  4 // 舞台剧
        this.getMenus()
      }else if (host == 'http://test-www.sdocenter.cn/') {
        this.linkItem =  4 // 舞台剧
        this.getMenus()
      } else {
        this.linkItem = 0
        // this.getMenus()
        // this.linkItem = 4
        // supernode
      }
    },
    watch: {
      usnInfo:{
        handler(newval, oldval) {
          if (newval) {
            let name
            let url
            if (this.usnInfo.user) {
              name = this.usnInfo.user.truename || this.usnInfo.user.nickname || this.usnInfo.user.username
              url = this.usnInfo.user.headimg
            } else {
              name = this.usnInfo.truename || this.usnInfo.nickname || this.usnInfo.username
              url = this.usnInfo.headimg || ''
            }
            this.usnurl = url
            this.usnName = name.length > 11 ? name.substring(0, 10) + '...' : name
          }
        },
        deep: true
      },
      '$route':{
        handler(newval, oldval) {
          if (this.$route.meta.shovalues){
            this.resizeFun()
          }
          let path = newval.path.split('/')[1]
          if (!path || !this.navList.length) {
            return false
          }
          if (this.navList.filter(val => val.path && val.path.includes(path)).length) {
            this.activeCode = this.navList.filter(val => val.path.includes(path))[0].path
            sessionStorage.setItem('activePath', this.activeCode)
          }
        },
        deep: true,
        immediate: true
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (to.path == '/') {
          if (window.location.host.includes('supernode') || window.location.host.includes('localhost') || window.location.host.includes('sdocenter')) {
            vm.$router.replace('/stagePlay')
          }
        }
      })
    },
    methods:{
      resizeFun(){
        let app = document.getElementById('app')
        setTimeout(() => {
          if (this.fixFlag) {
            this.fixFlag = document.documentElement.clientHeight > (app.offsetHeight + 300)
          } else {
            this.fixFlag = document.documentElement.clientHeight > app.offsetHeight
          }
        }, 200);
      },
      getMenus(){
        // let fd = new FormData()
        // fd.append('terminal', 1)
        // getMenusList(fd).then(res=>{
        //   this.navList = res.data
        // }).catch(err=>{
        //   console.log(err)
        // })
        this.navList = [{
          code: '1',
          label: '首页',
          path: '/stagePlay',
          children: []
        },{
          code: '2',
          label: '节点申请',
          path: '/nodeAccess',
          children: []
        },{
          code: '3',
          label: '版权注册',
          path: '/home',
          children: []
        },{
          code: '33',
          label: '版权交易',
          path: '/asseTlist',
          children: []
        },{
          code: '4',
          label: '版权发行',
          path: '/assetIssuance',
          children: []
        },{
          code: '5',
          label: '资产管理',
          path: '/assetManagement',
          children: []
        },{
          code: '6',
          label: '数字版权查询',
          path: '',
          children: [
            {
              code: 'licenseinfo',
              label: '可信版权链查证',
              url: 'https://www.licenseinfo.cn/'
            },
            {
              code: 'bjxwcbj',
              label: '北京市版权局区块链查证平台',
              url: 'https://szzs.bjxwcbj.gov.cn/'
            },
            {
              code: 'bjinternetcourt',
              label: '北京互联网法院天平链查证',
              url: 'https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch'
            },
          ]
        }]
      },
      tologin(){
        this.activeCode = '/login'
        this.$router.push({
          path: '/login'
        })
      },
      toTop(){
        this.activeCode = this.navList[0].path
        this.$router.push({
          path: this.navList[0].path
        })
      },
      toChildren(item){
        if (item.path === this.activeCode) {
          return
        }
        if (!item.path && !item.children.length) {
          this.$message('功能暂未开发')
          return
        }
        if (!Object.keys(this.$store.state.userData).length && item.path !== '/') {
          this.activeCode = item.path
          this.$message({
            message: '请先登录',
            duration: 1000
          })
          this.$router.push({
            path: '/login',
            query: {
              redirect: item.path
            }
          })
          return
        }
        if (item.path) {
          this.activeCode = item.path
          sessionStorage.setItem('activePath', item.path)
          this.$router.push({
            path: item.path
          })
        } else if (!item.children.length) {
          this.$message('功能暂未开发')
        }
      },
      handleCommand(command){
        switch (command) {
          case 'exitLogin':
          let that = this
          this.$confirm('是否要退出该账号', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            that.exitLogin()
            this.$store.commit({
              type: "setUserToken",
              userToken: ""
            })
            this.$store.commit({
              type: "setUserData",
              userData: ""
            })
            this.$store.commit({
              type: "setUnreadMessage",
              unreadMessage: "0"
            })
          }).catch(()=>{})
          break;
          case 'account':
          if (window.location.pathname == '/account') {
            break
          }
          this.activeCode = '/account'
          this.$router.push({
            path: '/account'
          })
          break;
          case 'oderGoods':
          if (this.activeCode === '/oderGoods') {
            break
          }
          this.activeCode = '/oderGoods'
          this.$router.push({
            path: '/oderGoods'
          })
          break;
        }
      },
      exitLogin(){
        sessionStorage.removeItem('usn')
        sessionStorage.removeItem('usn-token')
        sessionStorage.removeItem('usnInfo')
        this.usnInfo = ''
        this.$router.push({
          path: '/login'
        })
      },
      handleNav(command){
        if (command.path) {
          this.activeCode = command.path
          this.$router.push(command.path)
        } else {
          window.open(command.url, '_blank')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.el-dropdown{
  margin-right: 28px;
  .el-dropdown-link{
    color: #333;
  }
}
.headTitleBox{
      // .caaegati {
      //   // content: "";
      //   display: inline-block;
      //   width: 56px;
      //   height: 3px;
      //   background: #464646;
      //   position: relative;
      //   top: -55px;
      //   left: 3px;
      // }
     
    }
.caaeg{
  width: 100%;
  background: #FFFFFF;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.04);
}
.topNav{
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  height: 70px;
  width: 100%;
  // padding: 0 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  .navTag{
    display: flex;
    height: 100%;
    min-width: 1100px;
    max-width: 1200px;
    line-height: 70px;
    .logotip{
      width: 200px;
      height: 100%;
      margin-right: 30px;
      background: url('@/assets/logotip.svg') center top 14px no-repeat;
      background-size: 100% 40px;
    }
    .logoStage{
      // width: 138px;
      // width: 286px;
      // width: 230px;
      width: 280px;
      height: 100%;
      margin-right: 44px;
      // background: url('@/assets/stagePlay/logo.svg') center top 14px no-repeat;
      // background: url('@/assets/stagePlay/logo.svg') center top 14px no-repeat;
      background: url('@/assets/stagePlay/logo4.svg') center top 14px no-repeat;
      background-size: 100% 40px;
    }
    .logotipz{
      width: 200px;
      height: 100%;
      margin-right: 30px;
      background: url('@/assets/数商行logo.png') center top 14px no-repeat;
      background-size: 100% 48px;
    }
    .logoultural{
      width: 204px;
      height: 100%;
      margin-right: 30px;
      background: url('@/assets/文化大数据.png') center center no-repeat;
      background-size: 100% 48px;
    }
    .titlel{
      background: url('@/assets/logo@3x.png') center no-repeat;
      background-size: 100%;
      width: 214px;
      padding-left: 60px;
      margin-right: 60px;
      // margin-right: 30px;
      color: #004AFF;
      font-weight: bold;
    }
    .title{
      background: url('@/assets/logotop.png') left center no-repeat;
      
      background-size: 44px 44px;
      padding-left: 60px;
      margin-right: 40px;
      color: #004AFF;
      font-weight: bold;
    }
    .nav{
      // display: inline-block;
      margin-right: 28px;
      // width: 62px;
      text-align: center;
      cursor: pointer;
      &.active{
        position: relative;
        font-weight: 600px;
        color: #004aff;
      }
    }
  }
  .navBtn{
    div{
      cursor: pointer;
    }
    ::v-deep .el-dropdown-link{
      img{
        width: 38px;
        height: 38px;
        margin-right: 10px;
      }
    }
  }
}
</style>
